@charset "utf-8";


// Our variables
$base-font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:        #111;
$background-color:  #fffdf5; //#fdfdfd;
$brand-color:       #002147; //darkred; //#2a7ae2;
$brand-color-light: lighten($brand-color, 20%);
$text-color-light:  #fff;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

// Height of the navbar
$navbar-height: 100px;

$baseurl: "";

$on-palm:          600px;
$on-laptop:        800px;


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin not-media-query($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}

@mixin height-query($device) {
    @media screen and (max-height: $device) {
        @content;
    }
}

// Dracula font stacks
//$body-fonts: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
//$heading-fonts: "Noto Serif", "Inconsolata", "Times New Roman", Times, serif;
//$mono-fonts: Monaco, Bitstream Vera Sans Mono, Lucida Console, Terminal, monospace;


// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "custom",
        "papers"
;
