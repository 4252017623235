.papers {
    .paper {
        .paper-title {
            font-weight: bold;
        }

        .paper-date {
            color: $grey-color-dark;
        }

        .paper-awards {
            font-weight: bold;
        }

        .paper-venue {
            font-style: italic;
        }

        margin: 1em 0;
    }
}

.talks {
    .talk {
        .talk-title {
            font-weight: bold;
        }

        .talk-date {
            color: $grey-color-dark;
        }

        .talk-venue {
            font-style: italic;
        }

        margin: 1em 0;
    }
}