#home {
    display: flex;

    background: linear-gradient(#fafafa80, #fafafa80),url($baseurl + "/media/homebg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 101px);
    /*min-height: calc(100vh - 56px);*/

    text-align: center;
}

#home-inner {
    margin: auto;
    max-width: $content-width;
}

#photo {
    & img {
        border-radius: 50%;
        border: 1px solid #fff;
    }
    margin-bottom: $spacing-unit;
}

#home-heading {
    font-size: 48px;
    margin-bottom: 0;
}

#home-subheading {
    font-size: 30px;
    color: $grey-color;
    margin-bottom: $spacing-unit * 1.5;
}

#home-arrow {
    position: absolute;
    bottom: 20px;
    width: 80px;
    left: calc(50% - 40px);
    text-align: center;
    font-size: 3em;
    color: $brand-color;
    @include height-query(770px) {
        bottom: -12px;
    }
}

#home-lower {
    min-height: calc(100vh - 45px);
    font-size: 1.3em;
}

.home-lower-inner {
    max-width: 1000px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 80px auto;
}

// BUTTONS
a button {
    width: 100%;
}

.button {
    cursor: pointer;
    color: $text-color;
    //background-color: $background-color;
    background-color: transparent;
    text-align: center;
    vertical-align: middle;
    border: 1px solid $brand-color;
    border-radius: 0.25rem;
    font-size: 18px;
    padding: 8px 16px 8px 16px;
    // size stuff, dark colours and all

    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button-primary {
    &:hover {
        background-color: $brand-color-light;
        border-color: $brand-color-light;
    }
    &:active {
        background-color: lighten($brand-color-light, 4%);
        border-color: lighten($brand-color-light, 4%);
    }

    color: $text-color-light;
    background-color: $brand-color;
    border-color: $brand-color;
}

.small-only {
    @include media-query(35.5em) {
        display: inherit;
    }
    display: none;
}

.large-only {
    @include media-query(35.5em) {
        display: none;
    }
    display: inherit;
}

.project-container {
    width: 100%;
    background: white;
    padding: calc(#{$spacing-unit} / 2);
    border-radius: 8px;
    &:not(:last-child) {
        margin-bottom: $spacing-unit;
    }
    cursor: pointer;
}

.summary-container {
    width: 100%;
}

.square-image-box {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.square-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.project-thumbnail {
    @include media-query(35.5em) {
        width: 100%;
        padding-top: calc(#{$spacing-unit} / 2);
        padding-bottom: calc(#{$spacing-unit} / 2);
        padding-right: 0;
    }
    width: calc(100% - (#{$spacing-unit} / 2));
    padding-right: calc(#{$spacing-unit} / 2);
}

.summary-container .post-summary {
    margin-bottom: 0;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: $spacing-unit;
}

.table td {
    border-top: 1px solid $grey-color-light;
}

.social-links {
    list-style: none;
}

.carousel {
    border-top: 10px solid $grey-color-light;
    width: 100%;
    color: $grey-color;
    & img {
        @include media-query(35.5em) {
            min-width: 20vw;
            height: 60vw;
        }
        min-width: 10vw;
        max-width: initial;
        display: block;
        height: 24vw;
    }
}

.footnotes {
    font-size: 0.8em;
    color: $grey-color;
    margin-top: $spacing-unit;
}