/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font-weight: $base-font-weight;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-family: $base-font-family; // !important;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}

div * {
    font-family: $base-font-family; // !important;
}

html, button, input, select, textarea, .pure-g [class *= "pure-u"] {
    font-family: $base-font-family;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img, video {
    max-width: 100%;
    vertical-align: middle;
}


/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    color: $brand-color-light;
    text-decoration: none;

    &:visited {
        color: $brand-color-light;
    }

    &:hover {
        color: $brand-color;
        text-decoration: underline;
    }
}

a.secret {
    color: inherit;
    text-decoration: inherit;
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-family: "Roboto Mono", monospace !important;
    & * {
        font-family: "Roboto Mono", monospace !important;
    }
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    &.wrapper-header {
        max-width: -webkit-calc(100% - (#{$spacing-unit} * 2));
        max-width: calc(100% - (#{$spacing-unit} * 2));
    }

    &.wrapper-footer a {
        &, &:visited {
            color: darken($grey-color, 10%);
        }
    }

    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

/**
 * Modifiers
 */
.slab {
    font-family: "Roboto Slab", "Times New Roman", serif !important;
}

.mono {
    font-family: "Roboto Mono", monospace !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
    transition: box-shadow 0.15s ease-in-out;
}

.shadow-hover:hover {
    box-shadow: 0 0.1rem 0.2rem rgba(0,0,0,0.3);
}

.inline {
    float: left;
    margin-right: $spacing-unit / 2;
    margin-bottom: $spacing-unit / 2;
}
